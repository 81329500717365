// Be sure to import from the attribution build:
import { onINP, onCLS } from 'web-vitals/attribution';
import * as uuid from 'uuid'
import jsonToFormData from '@ajoelp/json-to-formdata'

const URI = '/api/wv/vitals'
const rayId = uuid.v4()

interface InpRequestData {
  name: string
  inp_event_target: string
  inp_event_type: string
  inp_duration: number
  cls_largest_shift_target: string
  cls_largest_shift_value: number
  cls_rating: number
  location: string
  rayId: string
  is_dashboard: boolean
  is_md_down: boolean
}

function handleMetrics ({name, attribution, rating}) {
  const mdDown = window.matchMedia("(max-width: 991px)").matches

  const { eventEntry, eventTarget, eventType, largestShiftTarget, largestShiftValue } = attribution;

  const data: InpRequestData = {
    name,
    inp_event_target: eventTarget,
    inp_event_type: eventType,
    // The total duration of the interaction. Note: this
    // value is rounded to 8 milliseconds of granularity:
    inp_duration: eventEntry?.duration,

    cls_largest_shift_target: largestShiftTarget,
    cls_largest_shift_value: largestShiftValue,
    cls_rating: rating,

    location: JSON.stringify({
      pathname: window?.location?.pathname,
      search: window?.location?.search,
      hash: window?.location?.hash,
      protocol: window?.location?.protocol,
    }),
    is_md_down: mdDown,
    // @ts-ignore
    is_dashboard: Boolean(window?._webWitalsIsDashboardFlag),
    rayId,
  };

  fetch(URI, {
    body: jsonToFormData(data, {
      // Add indexes to arrays
      arrayIndexes: true,
      // Exclude nulls from data items
      excludeNull: false,
    }),
    method: 'POST',
    keepalive: true,
  });
  return
}

onCLS(handleMetrics);
onINP(handleMetrics);